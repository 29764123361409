import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'google-maps-react';
import { useDepartments } from 'providers/DispatchProvider';
import { useTeam } from 'providers/TeamProvider';
import { GoogleMap } from 'components/ui';

const IncidentMap = props => {
  const team = useTeam();
  const departments = useDepartments();

  const deployedDepartments = useMemo(
    () =>
      !props.incident
        ? []
        : props.incident.departments
            .filter(i => !props.incident.closed_departments.includes(i))
            .map(i => departments.find(j => j.id === i)),
    [departments, props.incident]
  );

  return (
    <GoogleMap
      zoom={props.incident ? 12 : 8}
      initialCenter={{
        lat: props.incident.latitude || team.latitude,
        lng: props.incident.longitude || team.longitude
      }}
    >
      {/* incident marker */}
      <Marker
        title={props.incident.address}
        icon={require('assets/map-icons/incident.png')}
        position={{
          lat: props.incident.latitude,
          lng: props.incident.longitude
        }}
      />

      {/* department markers */}
      {deployedDepartments.map(item => (
        <Marker
          key={item.id}
          title={item.name}
          icon={require('assets/map-icons/fire-station.png')}
          position={{
            lat: item.latitude,
            lng: item.longitude
          }}
        />
      ))}
    </GoogleMap>
  );
};

IncidentMap.propTypes = {
  incident: PropTypes.object.isRequired
};

export default IncidentMap;
