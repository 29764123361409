import React from 'react';
import { Card, Loader } from 'components/ui';

const Splash = () => {
  return (
    <div className="h-screen flex justify-center items-center bg-gray-900">
      <Card
        title="Welcome"
        image={{ src: 'fireq-bst-logo.svg', alt: 'Breton SmarTek', width: 160 }}
        paddedMedia
      >
        <div className="flex flex-col justify-center items-center w-40 h-40">
          <p className="text-gray-700 font-semibold mb-2">Loading...</p>

          <Loader type="ThreeDots" width={80} height={80} />
        </div>
      </Card>
    </div>
  );
};

export default Splash;
