import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDepartments } from 'providers/DispatchProvider';
import IncidentMap from 'components/incident/IncidentMap';
import { Loader, Separator, NotFound } from 'components/ui';
import API from 'api';

const IncidentLog = props => {
  const params = useParams();
  const incidentId = params.id ? parseInt(params.id) : null;

  const [isLoading, setIsLoading] = useState(true);
  const [incident, setIncident] = useState(null);

  const departments = useDepartments();

  useEffect(() => {
    function fetchIncident() {
      API.incidents
        .fetch(incidentId)
        .then(response => setIncident(response.data.incident))
        .catch(error => console.error(error))
        .finally(() => setIsLoading(false));
    }

    if (incidentId) {
      fetchIncident();
    }
  }, [incidentId]);

  const deployedDepartments = useMemo(() => {
    if (!incident) {
      return [];
    }

    return [...departments].filter(item =>
      incident.departments.includes(item.id)
    );
  }, [incident, departments]);

  if (!incidentId && !incident) {
    return <NotFound />;
  }

  return (
    <div className="flex w-full h-full">
      <div className="w-1/3 xl:w-1/4 max-h-full mr-2 p-4 bg-gray-100 shadow-inner overflow-y-auto">
        <h1 className="text-gray-700 font-bold text-xl">Incident Details</h1>
        <Separator />

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <LineItem title="Dispatched By">{incident.creator.name}</LineItem>
            <LineItem title="Dispatch At">
              {incident.local_created_at.datetime}
            </LineItem>
            <LineItem title="Incident Type">
              {incident.incident_type_name}
            </LineItem>
            <LineItem title="Address">{incident.address}</LineItem>
            <LineItem title="Description">
              {incident.description || 'N/A'}
            </LineItem>
            <LineItem title="Departments Dispatched">
              <ul className="list-disc px-6">
                {deployedDepartments.map(item => (
                  <li key={item.name} className="py-1">
                    {item.name}
                  </li>
                ))}
              </ul>
            </LineItem>
          </>
        )}
      </div>

      <div className="w-2/3 xl:w-3/4 h-full">
        {incident && (
          <IncidentMap departments={deployedDepartments} incident={incident} />
        )}
      </div>
    </div>
  );
};

const LineItem = props => (
  <div className="w-full mb-1 py-2 border-b border-gray-200">
    <div className="font-semibold text-gray-800">{props.title}</div>

    <div className="text-sm text-gray-600">{props.children}</div>
  </div>
);

export default IncidentLog;
