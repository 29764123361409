import React from 'react';
import PropTypes from 'prop-types';

const Card = props => (
  <div className="flex flex-col rounded overflow-hidden shadow-lg bg-white">
    <div className="p-3">
      {props.title && (
        <div
          className={`text-${
            props.titleCentered ? 'center' : 'left'
          } text-gray-700 text-sm font-semibold`}
        >
          {props.title}
        </div>
      )}

      <div className="mt-2">{props.children}</div>
    </div>

    {props.actions && (
      <div className="flex justify-end w-full py-3 px-2 bg-gray-400">
        {props.actions}
      </div>
    )}
  </div>
);

Card.propTypes = {
  title: PropTypes.string,
  titleCentered: PropTypes.bool,
  actions: PropTypes.element
};

Card.defaultProps = {
  title: null,
  titleCentered: false,
  actions: null
};

export default Card;
