import React from 'react';
import AuthProvider from 'providers/AuthProvider';
import TeamProvider from 'providers/TeamProvider';
import DispatchProvider from 'providers/DispatchProvider';

const AppProviders = props => {
  return (
    <AuthProvider>
      <TeamProvider>
        <DispatchProvider>{props.children}</DispatchProvider>
      </TeamProvider>
    </AuthProvider>
  );
};

export default AppProviders;
