const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return { ...state, error: action.error };
    case 'SET_IS_LOADING':
      return { ...state, loading: action.isLoading };
    case 'SET_USER':
      return { ...state, user: action.user };
    default:
      throw new Error('Invalid auth reducer action');
  }
};

export default reducer;
