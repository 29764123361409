import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

const Pagination = props => <ReactPaginate {...props} />;

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  initialPage: PropTypes.number,
  onPageChange: PropTypes.func,
  marginPagesDisplayed: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  previousLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  breakLabel: PropTypes.string,
  containerClassName: PropTypes.string,
  pageClassName: PropTypes.string,
  pageLinkClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  breakClassName: PropTypes.string,
  breakLinkClassName: PropTypes.string,
  previousClassName: PropTypes.string,
  nextClassName: PropTypes.string
};

Pagination.defaultProps = {
  initialPage: 0,
  onPageChange: () => {},
  marginPagesDisplayed: 2,
  pageRangeDisplayed: 5,
  previousLabel: 'Previous',
  nextLabel: 'Next',
  breakLabel: '...',
  containerClassName: 'flex justify-center my-2 py-1',
  pageClassName:
    'w-10 h-10 mx-px px-1 bg-blue-500 hover:bg-blue-600 text-white rounded',
  pageLinkClassName: 'w-full h-full flex justify-center items-center',
  activeClassName: 'bg-blue-700',
  breakClassName:
    'w-10 h-10 mx-px px-1 bg-blue-500 hover:bg-blue-600 text-white rounded',
  breakLinkClassName: 'w-full h-full flex justify-center items-center',
  previousClassName:
    'flex justify-center items-center mr-2 px-2 bg-gray-600 hover:bg-gray-700 text-white rounded',
  nextClassName:
    'flex justify-center items-center ml-2 px-2 bg-gray-600 hover:bg-gray-700 text-white rounded'
};

export default Pagination;
