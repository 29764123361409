import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'google-maps-react';

const mapStyle = {
  width: '100%',
  height: '100%',
};

const MapContainer = (props) => (
  <div className="w-full h-full relative">
    <Map
      {...props}
      google={window.google}
      options={{ draggableCursor: 'crosshair' }}
      zoom={props.zoom}
      initialCenter={props.initialCenter}
      center={props.center}
      style={mapStyle}
    >
      {props.children}
    </Map>
  </div>
);

MapContainer.propTypes = {
  zoom: PropTypes.number,
  initialCenter: PropTypes.object,
  center: PropTypes.object,
};

MapContainer.defaultProps = {
  zoom: 7,
  initialCenter: { lat: 45.857444, lng: -64.281818 },
  center: { lat: 45.857444, lng: -64.281818 },
};

export default MapContainer;
