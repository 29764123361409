import React from 'react';

const NotFound = props => (
  <div className="flex flex-col justify-center items-center w-full h-full">
    <span className="text-3xl font-bold text-gray-700">Sorry</span>
    <span className="mt-2 text-xl font-semibold text-gray-600">
      This page does not exist!
    </span>

    <span className="mt-2 text-3xl font-bold text-red-500">(404)</span>
  </div>
);

export default NotFound;
