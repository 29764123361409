import axios from 'axios';
import { BASE_URL } from 'api/index';

export default {
  fetchMyTeams() {
    return axios.get(`${BASE_URL}/me/dispatch-teams`);
  },

  fetch(teamId) {
    return axios.get(`${BASE_URL}/dispatch/${teamId}`);
  }
};
