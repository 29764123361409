import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIncidents } from 'providers/DispatchProvider';
import { Card, List, ListItem, NoContent } from 'components/ui';

const ActiveIncidents = (props) => {
  const history = useHistory();
  const incidents = useIncidents();

  function select(incident) {
    history.push(`/incidents/${incident.id}`);
  }

  return (
    <div className="flex w-full justify-center">
      <div className="w-full w-2/3 lg:w-2/3 xl:w-1/2">
        <Card title="Active Incidents">
          {incidents.length === 0 ? (
            <NoContent message="There are currently no active incidents..." />
          ) : (
            <List heading="Incidents" searchable>
              {(query) =>
                incidents
                  .filter(
                    (incident) =>
                      query.length === 0 ||
                      incident.address
                        .toLowerCase()
                        .includes(query.toLowerCase())
                  )
                  .map((incident) => (
                    <ListItem
                      key={incident.id}
                      chevron
                      title={incident.address || 'N/A'}
                      subtitle={`(${incident.local_created_at.datetime}) 
                      ${incident.incident_type_name}
                      `}
                      onClick={() => select(incident)}
                    />
                  ))
              }
            </List>
          )}
        </Card>
      </div>
    </div>
  );
};

export default ActiveIncidents;
