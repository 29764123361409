import axios from 'axios';
import { BASE_URL } from 'api/index';

export default {
  fetchLog(teamId, page = 1) {
    return axios.get(
      `${BASE_URL}/dispatch/${teamId}/incidents/log?page=${page}`
    );
  },

  fetch(incidentId) {
    return axios.get(`${BASE_URL}/dispatch-incidents/${incidentId}`);
  },

  fetchNotes(incidentId) {
    return axios.get(`${BASE_URL}/dispatch-incidents/${incidentId}/notes`);
  },

  update(incidentId, data) {
    return axios.patch(`${BASE_URL}/dispatch-incidents/${incidentId}`, data);
  }
};
