import React from 'react';
import { useUser } from 'providers/AuthProvider';
import { useTeam } from 'providers/TeamProvider';

const Navbar = () => {
  const user = useUser();
  const team = useTeam();

  return (
    <div className="flex justify-between items-center w-full h-12 py-2 px-4 border-b border-b-500 bg-gray-200">
      <div className="">
        <span className="font-semibold text-gray-600">{team.name}</span>
      </div>

      <div className="" />

      <div className="">
        <span className="text-gray-800 font-semibold">{user.name}</span>
      </div>
    </div>
  );
};

export default Navbar;
