import React, { useContext, useState } from 'react';
import { TeamContext, SELECTED_TEAM_KEY } from 'providers/TeamProvider';
import { Button, Card, HelpText, List, ListItem } from 'components/ui';

const TeamSelect = () => {
  const context = useContext(TeamContext);
  const [selected, setSelected] = useState(null);

  function choose() {
    if (!selected) {
      return;
    }

    window.localStorage.setItem(SELECTED_TEAM_KEY, selected.id);
    context.dispatch({ type: 'SET_TEAM', team: selected });
  }

  return (
    <div className="h-screen flex justify-center items-center bg-gray-900">
      <Card
        title="Select Dispatch Center"
        actions={
          <Button primary disabled={!selected} onClick={choose}>
            Select
          </Button>
        }
      >
        <div style={{ width: 400 }}>
          <HelpText>
            You are a member of multiple dispatch centers. Once you make your
            selection we will automatically load this one in the future. You can
            change your selected dispatch center later.
          </HelpText>

          <List>
            {context.state.teams.map(team => (
              <ListItem
                key={team.id}
                title={team.name}
                selected={selected && team.id === selected.id}
                onClick={() => setSelected(team)}
              />
            ))}
          </List>
        </div>
      </Card>
    </div>
  );
};

export default TeamSelect;
