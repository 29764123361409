import React, { useState } from 'react';
import PropTypes from 'prop-types';

const List = (props) => {
  const [query, setQuery] = useState('');

  return (
    <div className="w-full pb-2">
      {props.searchable && (
        <input
          type="text"
          placeholder="Search..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      )}

      <div className="mt-2 overflow-y-auto" style={{ height: props.height }}>
        {props.searchable ? props.children(query) : props.children}
      </div>
    </div>
  );
};

List.propTypes = {
  searchable: PropTypes.bool,
};

List.defaultProps = {
  searchable: false,
  height: 'none',
};

export default List;
