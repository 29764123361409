import axios from 'axios';
import { BASE_URL } from 'api/index';

export default {
  fetch(teamId) {
    return axios.get(`${BASE_URL}/dispatch/${teamId}/groups`);
  },

  create(teamId, data) {
    return axios.post(`${BASE_URL}/dispatch/${teamId}/groups`, data);
  },

  update(groupId, data) {
    return axios.patch(`${BASE_URL}/dispatch-groups/${groupId}`, data);
  },

  remove(groupId) {
    return axios.delete(`${BASE_URL}/dispatch-groups/${groupId}`);
  }
};
