import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppProviders from 'providers';
import AppLayout from 'layouts/AppLayout';
import DashboardPage from 'pages/Dashboard';
import ActiveIncidentsPage from 'pages/ActiveIncidents';
import IncidentLogPage from 'pages/IncidentLog';
import IncidentInfoPage from 'pages/IncidentInfoPage';
import IncidentPage from 'pages/Incident';
import GroupsPage from 'pages/Groups';

const App = () => {
  return (
    <AppProviders>
      <Router>
        <AppLayout>
          <Switch>
            <Route exact path="/">
              <DashboardPage />
            </Route>

            <Route path="/active-incidents">
              <ActiveIncidentsPage />
            </Route>

            <Route path="/incident-log" exact>
              <IncidentLogPage />
            </Route>

            <Route path="/incident-log/:id">
              <IncidentInfoPage />
            </Route>

            <Route path="/incidents/:id">
              <IncidentPage />
            </Route>

            <Route path="/groups">
              <GroupsPage />
            </Route>
          </Switch>
        </AppLayout>
      </Router>
    </AppProviders>
  );
};

export default App;
