import React from 'react';
import { Link, Route, useRouteMatch } from 'react-router-dom';
import { useGroups } from 'providers/DispatchProvider';
import CreateGroupPage from 'pages/CreateGroup';
import UpdateGroupPage from 'pages/UpdateGroup';
import { Button, Card, List, ListItem, NoContent } from 'components/ui';

const MutualAidGroups = props => {
  const { path, url } = useRouteMatch();
  const groups = useGroups();

  return (
    <div className="flex w-full">
      <div className="w-1/2 lg:w-1/3 mr-4">
        <Card title="Groups">
          <>
            <div className="flex justify-between items-center w-full mb-2 py-2 px-4">
              <span />

              <div>
                <Link to={`${url}/create`}>
                  <Button success>Create Group</Button>
                </Link>
              </div>
            </div>

            {groups.length === 0 ? (
              <NoContent message="You currently have no groups..." />
            ) : (
              <List heading="Groups" searchable>
                {query =>
                  groups
                    .filter(
                      group =>
                        query.length === 0 ||
                        group.name.toLowerCase().includes(query.toLowerCase())
                    )
                    .map(group => (
                      <Link key={group.id} to={`${url}/${group.id}/edit`}>
                        <ListItem
                          chevron
                          title={group.name}
                          subtitle={`(${group.departments.length}) Departments`}
                        />
                      </Link>
                    ))
                }
              </List>
            )}
          </>
        </Card>
      </div>

      <div className="flex-1">
        <Route path={`${path}/create`}>
          <CreateGroupPage />
        </Route>

        <Route path={`${path}/:groupId/edit`}>
          <UpdateGroupPage />
        </Route>
      </div>
    </div>
  );
};

export default MutualAidGroups;
