import React from 'react';
import { Link, matchPath, withRouter } from 'react-router-dom';
import {
  MdDashboard,
  MdExitToApp,
  MdGroupWork,
  MdHistory,
  MdGpsFixed,
} from 'react-icons/md';
import { useAuth } from 'providers/AuthProvider';
import { useTeam } from 'providers/TeamProvider';
import { useDepartments } from 'providers/DispatchProvider';

const Sidebar = (props) => {
  const authContext = useAuth();
  const team = useTeam();
  const departments = useDepartments();

  const currentRoute = props.location.pathname;

  function logout() {
    if (window.confirm('Are you sure you want to logout?')) {
      return authContext.logout();
    }
  }

  return (
    <div className="flex-none w-20 lg:w-56 bg-gray-100 border-r border-gray-300">
      <div className="flex justify-center items-center w-full h-16 lg:h-24 mb-8 border-b border-gray-300">
        <img
          src={require(`assets/img/fireq-bst-logo.svg`)}
          alt="FireQ"
          className="hidden lg:inline w-3/4"
        />

        <img
          src={require(`assets/img/fireq-logo.svg`)}
          alt="FireQ"
          className="inline lg:hidden w-1/2"
        />
      </div>

      <div className="w-full mb-4">
        <div className="text-center lg:text-left font-gray-700 font-semibold px-4 text-xs lg:text-sm mb-2">
          Dispatch
        </div>

        <Link to="/">
          <div
            className={`flex justify-center lg:justify-start items-center p-4 text-sm text-gray-600 cursor-pointer hover:bg-gray-300 ${
              matchPath(currentRoute, { path: '/', exact: true })
                ? 'bg-gray-300'
                : ''
            }`}
          >
            <MdDashboard className="text-2xl lg:mr-3" />
            <span className="hidden lg:inline">Dashboard</span>
          </div>
        </Link>

        <Link to="/active-incidents">
          <div
            className={`flex justify-center lg:justify-start items-center p-4 text-sm text-gray-600 cursor-pointer hover:bg-gray-300 ${
              matchPath(currentRoute, {
                path: '/active-incidents',
                exact: true,
              }) || matchPath(currentRoute, { path: '/incidents' })
                ? 'bg-gray-300'
                : ''
            }`}
          >
            <MdGpsFixed className="text-2xl lg:mr-3" />
            <span className="hidden lg:inline">Active Incidents</span>
          </div>
        </Link>

        <Link to="/incident-log">
          <div
            className={`flex justify-center lg:justify-start items-center p-4 text-sm text-gray-600 cursor-pointer hover:bg-gray-300 ${
              matchPath(currentRoute, {
                path: '/incident-log',
              })
                ? 'bg-gray-300'
                : ''
            }`}
          >
            <MdHistory className="text-2xl lg:mr-3" />
            <span className="hidden lg:inline">Incident Log</span>
          </div>
        </Link>

        {!team.can_dispatch_by_group && departments.length > 1 && (
          <Link to="/groups">
            <div
              className={`flex justify-center lg:justify-start items-center p-4 text-sm text-gray-600 cursor-pointer hover:bg-gray-300 ${
                matchPath(currentRoute, {
                  path: '/groups',
                })
                  ? 'bg-gray-300'
                  : ''
              }`}
            >
              <MdGroupWork className="text-2xl lg:mr-3" />
              <span className="hidden lg:inline">Groups</span>
            </div>
          </Link>
        )}
      </div>

      <div className="w-full">
        <div className="text-center lg:text-left font-gray-700 font-semibold px-4 text-xs lg:text-sm mb-2">
          Other
        </div>

        <div
          className="flex justify-center lg:justify-start items-center p-4 text-sm text-red-500 cursor-pointer hover:bg-gray-300"
          onClick={logout}
        >
          <MdExitToApp className="text-2xl lg:mr-3" />
          <span className="hidden lg:inline">Logout</span>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
