import React, { useEffect, useState } from 'react';
import semver from 'semver';
import { version } from '../../package.json';
import API from 'api';

function UpdateBanner() {
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);

  useEffect(() => {
    function fetchLatestVersions() {
      API.fetchLatestVersions().then(response => {
        const latestDispatchVersion = response.data.versions.dispatch_module;

        if (!latestDispatchVersion) {
          return;
        }

        setIsUpdateAvailable(semver.gt(latestDispatchVersion, version));
      });
    }

    const interval = setInterval(() => {
      fetchLatestVersions();
    }, 3600000);

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refresh() {
    window.location.reload();
  }

  return isUpdateAvailable ? (
    <div className="flex justify-center items-center w-full h-16 absolute bottom-0 left-0 bg-blue-600">
      <span className="text-white text-lg">
        We made some changes to the dispatch module. Please refresh to load the
        changes.
        <button
          type="button"
          className="ml-8 rounded text-gray-800 bg-gray-200 p-2"
          onClick={refresh}
        >
          Reload
        </button>
      </span>
    </div>
  ) : null;
}

export default UpdateBanner;
