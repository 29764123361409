import React from 'react';
import PropTypes from 'prop-types';
import { MdCheckCircle, MdChevronRight } from 'react-icons/md';

const ListItem = (props) => {
  return (
    <div
      className={`flex w-full p-2 mb-1 select-none ${
        props.selected ? 'bg-blue-600' : 'bg-white'
      } ${
        props.selected ? 'text-blue-100' : 'text-gray-800'
      } text-sm rounded cursor-pointer ${
        props.selected ? '' : 'hover:bg-gray-200'
      }`}
      onClick={props.onClick}
    >
      {props.selectable && (
        <div className="flex justify-center items-center w-8 mr-2">
          {props.selected ? (
            <span className="text-xl text-blue-200">
              <MdCheckCircle />
            </span>
          ) : (
            <span className="w-4 h-4 rounded-full border border-gray-400"></span>
          )}
        </div>
      )}

      <div className="flex flex-col flex-1">
        {!!props.subtitle && (
          <span className="font-semibold">{props.title}</span>
        )}

        {!props.subtitle && <span>{props.title}</span>}

        {props.subtitle && <div>{props.subtitle}</div>}
      </div>

      {props.chevron && (
        <div className="flex justify-center items-center w-12">
          <span className="text-xl text-gray-700">
            <MdChevronRight />
          </span>
        </div>
      )}
    </div>
  );
};

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  chevron: PropTypes.bool,
  onClick: PropTypes.func,
};

ListItem.defaultProps = {
  subtitle: null,
  selectable: false,
  selected: false,
  chevron: false,
  onClick: () => {},
};

export default ListItem;
