import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTeam } from 'providers/TeamProvider';
import { Loader, Separator } from 'components/ui';
import API from 'api';

const DepartmentResponderStats = props => {
  const team = useTeam();

  const [isLoading, setIsLoading] = useState(false);
  const [responders, setResponders] = useState({
    total: 0,
    yes: 0,
    no: 0,
    hold: 0
  });

  useEffect(() => {
    if (!props.departmentId) {
      return;
    }

    fetchResponders();

    function fetchResponders() {
      setIsLoading(true);

      API.dispatch
        .fetchResponders(team.id, props.departmentId)
        .then(response => {
          setResponders(response.data.responders);
        })
        .catch(error => console.error(error))
        .finally(() => setTimeout(() => setIsLoading(false), 1500));
    }

    const interval = setInterval(() => {
      fetchResponders();
    }, 60000);

    return () => clearInterval(interval);
  }, [team, props.departmentId]);

  return (
    <div>
      <div className="flex items-center">
        {isLoading && <Loader color="#e74c3c" width={30} height={30} />}

        <span
          className={`ml-${isLoading ? '2' : '0'} text-gray-600 font-semibold`}
        >
          {props.title}
        </span>
      </div>

      <div className="flex w-full h-16 mt-2 bg-red-500">
        <StatBox title="Total" color="blue" count={responders.total} />
        <StatBox title="Yes" color="green" count={responders.yes} />
        <StatBox title="No" color="red" count={responders.no} />
        <StatBox title="On Hold" color="gray" count={responders.hold} />
      </div>

      <Separator />
    </div>
  );
};

DepartmentResponderStats.propTypes = {
  title: PropTypes.string.isRequired,
  departmentId: PropTypes.number,
  updateTotal: PropTypes.func
};

DepartmentResponderStats.defaultProps = {
  departmentId: null,
  updateTotal: () => {}
};

const StatBox = props => (
  <div className={`flex flex-col flex-1 h-full bg-${props.color}-500`}>
    <div
      className={`flex justify-center items-center w-full h-6 bg-${props.color}-600`}
    >
      <span className="text-sm text-white">{props.title}</span>
    </div>

    <div className="flex-1 flex justify-center items-center w-full">
      <span className="font-semibold text-xl text-white">{props.count}</span>
    </div>
  </div>
);

StatBox.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired
};

export default DepartmentResponderStats;
