import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import API from 'api';
import { useTeam } from 'providers/TeamProvider';
import { Card, Pagination, List, ListItem, NoContent } from 'components/ui';

const IncidentLog = props => {
  const history = useHistory();
  const team = useTeam();

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [incidents, setIncidents] = useState([]);

  useEffect(() => {
    function fetchIncidents(page) {
      API.incidents
        .fetchLog(team.id, page)
        .then(response => {
          setIncidents(response.data.incidents.data);
          setPageCount(response.data.incidents.last_page);
        })
        .catch(error => console.error(error));
    }

    fetchIncidents(currentPage);
  }, [team, currentPage]);

  function select(incident) {
    history.push(`/incident-log/${incident.id}`);
  }

  return (
    <div className="flex w-full justify-center">
      <div className="w-full w-2/3 lg:w-2/3 xl:w-1/2">
        <Card title="Incident Log">
          {incidents.length === 0 ? (
            <NoContent />
          ) : (
            <>
              {pageCount > 1 && (
                <Pagination
                  pageCount={pageCount}
                  initialPage={currentPage}
                  onPageChange={({ selected }) => setCurrentPage(selected + 1)}
                />
              )}

              <List heading="Incidents" searchable>
                {query =>
                  incidents
                    .filter(
                      incident =>
                        query.length === 0 ||
                        incident.address
                          .toLowerCase()
                          .includes(query.toLowerCase())
                    )
                    .map(incident => (
                      <ListItem
                        key={incident.id}
                        chevron
                        title={incident.address}
                        subtitle={`(${incident.local_created_at.datetime}) 
                      ${incident.incident_type_name}
                      `}
                        onClick={() => select(incident)}
                      />
                    ))
                }
              </List>
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default IncidentLog;
