import React from 'react';
import PropTypes from 'prop-types';

const NoContent = props => (
  <div className="w-full py-4 text-center">
    <span className="font-semibold text-lg text-gray-500 italics">
      {props.message}
    </span>
  </div>
);

NoContent.propTypes = {
  message: PropTypes.string
};

NoContent.defaultProps = {
  message: 'Nothing to display...'
};

export default NoContent;
