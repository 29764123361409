import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDepartments } from 'providers/DispatchProvider';
import { List, ListItem } from 'components/ui';

const DepartmentGroupList = (props) => {
  const [hasInitialized, setHasInitialized] = useState(false);
  const [departmentGroups, setDepartmentGroups] = useState({});

  const departments = useDepartments();

  useEffect(() => {
    props.onChange(departmentGroups);
  }, [departmentGroups, props]);

  useEffect(() => {
    if (hasInitialized || departments.length === 0) {
      return;
    }

    setDepartmentGroups(
      departments.reduce((obj, val) => ({ ...obj, [val.id]: [] }), {})
    );

    setHasInitialized(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments]);

  const selectedDepartments = useMemo(() => {
    return Object.keys(departmentGroups)
      .filter((key) => departmentGroups[key].length > 0)
      .map((i) => parseInt(i));
  }, [departmentGroups]);

  function select(department, group) {
    if (group === 'everybody') {
      setDepartmentGroups((pV) => {
        return {
          ...pV,
          [department.id]:
            department.message_groups.length === 0
              ? departmentGroups[department.id].includes('everybody')
                ? []
                : ['everybody']
              : departmentGroups[department.id].length <
                department.message_groups.length
              ? ['everybody', ...department.message_groups.map((g) => g.id)]
              : [],
        };
      });

      return;
    }

    let groups = [...departmentGroups[department.id]].filter(
      (x) => x !== 'everybody'
    );

    const index = groups.indexOf(group.id);
    if (index === -1) {
      groups.push(group.id);
    } else {
      groups.splice(index, 1);
    }

    if (groups.length === department.message_groups.length) {
      groups.push('everybody');
    }

    setDepartmentGroups((pV) => ({
      ...pV,
      [department.id]: groups,
    }));
  }

  function isGroupSelected(department, groupId) {
    return departmentGroups[department.id].includes(groupId);
  }

  return (
    <div className="w-full">
      <hr />

      {hasInitialized ? (
        <div className="pt-4">
          {departments.map((department) => (
            <div key={department.id}>
              <h1
                className={`text-${
                  selectedDepartments.includes(department.id) ? 'green' : 'red'
                }-700 font-bold underline`}
              >
                {department.name}
              </h1>

              <List>
                <ListItem
                  title="Everybody"
                  selectable
                  selected={isGroupSelected(department, 'everybody')}
                  onClick={() => select(department, 'everybody')}
                />

                {department.message_groups.map((group) => (
                  <ListItem
                    key={group.id}
                    title={group.name}
                    selectable
                    selected={isGroupSelected(department, group.id)}
                    onClick={() => select(department, group)}
                  />
                ))}
              </List>
            </div>
          ))}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

DepartmentGroupList.propTypes = {
  onChange: PropTypes.func,
};

DepartmentGroupList.defaultProps = {
  onChange: () => {},
};

export default DepartmentGroupList;
