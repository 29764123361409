import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatchContext } from 'providers/DispatchProvider';
import { Button, Card, PlacesAutocomplete, Separator } from 'components/ui';
import API from 'api';

function IncidentUpdate(props) {
  const dispatchContext = useDispatchContext();

  const [incidentTypes, setIncidentTypes] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [incidentType, setIncidentType] = useState(-1);
  const [otherIncidentType, setOtherIncidentType] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  useEffect(() => {
    function loadDefaults() {
      setAddress(props.incident.address);
      setDescription(props.incident.description);
      setLatitude(props.incident.latitude);
      setLongitude(props.incident.longitude);

      if (props.incident.incident_type_other) {
        setIncidentType(0);
        setOtherIncidentType(props.incident.incident_type_other);
      } else {
        setIncidentType(props.incident.incident_type_id);
      }
    }

    loadDefaults();
    fetchIncidentTypes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchIncidentTypes() {
    API.data
      .fetchIncidentTypes()
      .then((response) => setIncidentTypes(response.data.incident_types))
      .catch((error) => console.error(error));
  }

  function selectPlace(place) {
    setAddress(place.address);
    setLatitude(place.lat);
    setLongitude(place.lng);
  }

  const readyToSubmit = useMemo(() => {
    if ((!incidentType || incidentType === 0) && !otherIncidentType) {
      return false;
    }

    if (!address && (!latitude || !longitude)) {
      return false;
    }

    return true;
  }, [address, incidentType, latitude, longitude, otherIncidentType]);

  async function update() {
    if (!readyToSubmit) {
      return;
    }

    setIsProcessing(true);

    try {
      const response = await API.incidents.update(props.incident.id, {
        incidentType: incidentType > 0 ? incidentType : null,
        otherIncidentType,
        description,
        address,
        latitude,
        longitude,
      });

      const incident = response.data.incident;
      dispatchContext.dispatch({ type: 'UPDATE_INCIDENT', incident });

      setIsProcessing(false);
      props.onFinishedEditing();
      alert('Incident has been updated');
    } catch (error) {
      setIsProcessing(false);
    }
  }

  return (
    <div className="flex justify-center mb-2">
      <div className="w-full lg:w-3/4 xl:w-1/2">
        <Card title="Incident Information">
          <div className="mt-4">
            <label className="label">Incident Type</label>

            <select
              value={incidentType}
              onChange={(e) =>
                setIncidentType(
                  e.target.value >= 0 ? parseInt(e.target.value) : undefined
                )
              }
            >
              <option value={-1}>---</option>
              <option value={0}>--- Other ---</option>

              {incidentTypes.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          {incidentType === 0 && (
            <div className="mt-4">
              <label className="label">Other Incident Type</label>
              <input
                type="text"
                placeholder="Other Incident Type"
                value={otherIncidentType}
                onChange={(e) => setOtherIncidentType(e.target.value)}
              />
            </div>
          )}

          <div className="mt-4">
            <label className="label">Description</label>
            <input
              type="text"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <Separator text="Search for address" />

          <div>
            <label className="label">Address</label>
            <PlacesAutocomplete
              value={address || ''}
              onChange={(val) => setAddress(val)}
              onSelect={selectPlace}
            />
          </div>

          <Separator or text="Enter coordinates" />

          <div className="flex">
            <div className="w-1/2 mr-1">
              <label className="label">Latitude</label>
              <input
                type="text"
                placeholder="Latitude"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
              />
            </div>

            <div className="w-1/2 mr-1">
              <label className="label">Longitude</label>
              <input
                type="text"
                placeholder="Longitude"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-col justify-center mt-6">
            <Button
              primary
              disabled={isProcessing || !readyToSubmit}
              loading={isProcessing}
              onClick={update}
              className="mb-2"
            >
              {isProcessing ? 'Updating' : 'Update Incident'}
            </Button>

            <Button danger onClick={props.onFinishedEditing}>
              Cancel
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}

IncidentUpdate.propTypes = {
  incident: PropTypes.object.isRequired,
  onFinishedEditing: PropTypes.func.isRequired,
};

export default IncidentUpdate;
