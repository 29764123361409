import React from 'react';
import UpdateBanner from 'components/UpdateBanner';
import Sidebar from 'components/navigation/Sidebar';
import Navbar from 'components/navigation/Navbar';

const AppLayout = (props) => {
  return (
    <div className="flex w-screen h-full min-h-screen bg-gray-300">
      <UpdateBanner />

      <Sidebar />

      <div className="flex flex-col flex-1">
        <Navbar />

        <div className="page-content">{props.children}</div>
      </div>
    </div>
  );
};

export default AppLayout;
