import React from 'react';
import PropTypes from 'prop-types';

const Separator = props => (
  <div className="flex items-center p-4">
    <div className="flex-1 h-px bg-gray-300" />

    {props.text && (
      <span className="mx-6 text-sm italic text-gray-500 italic">
        {props.or && <span className="font-bold">(Or) </span>}
        {props.and && <span className="font-bold">(And) </span>}

        <span>{props.text}</span>
      </span>
    )}

    <div className="flex-1 h-px bg-gray-300" />
  </div>
);

Separator.propTypes = {
  text: PropTypes.string,
  or: PropTypes.bool,
  and: PropTypes.bool
};

Separator.defaultProps = {
  text: null,
  or: false,
  and: false
};

export default Separator;
