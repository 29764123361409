import axios from 'axios';
import { BASE_URL } from 'api/index';

export default {
  login(email, password) {
    return axios.post(`${BASE_URL}/auth/dispatch-login`, { email, password });
  },

  fetchProfile() {
    return axios.get(`${BASE_URL}/me`);
  },

  updateProfile(data) {
    return axios.patch(`${BASE_URL}/me`, data);
  },
};
