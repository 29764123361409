import axios from 'axios';
import auth from 'api/auth';
import teams from 'api/teams';
import data from 'api/data';
import departments from 'api/departments';
import dispatch from 'api/dispatch';
import incidents from 'api/incidents';
import groups from 'api/groups';

export const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://fireq.test/api'
    : 'https://fire-api.qmmunicate.com/api';

export default {
  auth,
  teams,
  data,
  departments,
  dispatch,
  incidents,
  groups,

  setToken: token => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },

  setSocketId: socketId => {
    axios.defaults.headers.common['X-Socket-ID'] = socketId;
  },

  removeSocketId: () => {
    delete axios.defaults.headers.common['X-Socket-ID'];
  },

  fetchLatestVersions: () => axios.get(`${BASE_URL}/latest-versions`)
};
