const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return { ...state, error: action.error };
    case 'SET_IS_LOADING':
      return { ...state, loading: action.isLoading };
    case 'SET_TEAM':
      return { ...state, team: action.team };
    case 'SET_TEAMS':
      return { ...state, teams: action.teams };
    default:
      throw new Error('Invalid team reducer action');
  }
};

export default reducer;
