import React from 'react';
import PropTypes from 'prop-types';
import { useGroups } from 'providers/DispatchProvider';
import { List, ListItem, NoContent } from 'components/ui';

const GroupList = (props) => {
  const groups = useGroups();

  function select(group) {
    const index = props.selected.findIndex((item) => item === group.id);

    let value = [...props.selected];
    if (index > -1) {
      value.splice(index, 1);
    } else {
      value.push(group.id);
    }

    props.onChange(value);
  }

  return groups.length > 0 ? (
    <List heading="Groups" searchable>
      {(query) =>
        groups
          .filter(
            (group) =>
              query.length === 0 ||
              group.name.toLowerCase().includes(query.toLowerCase())
          )
          .map((group) => (
            <ListItem
              key={group.id}
              title={group.name}
              subtitle={`(${group.departments.length}) Departments`}
              selectable
              selected={props.selected.includes(group.id)}
              onClick={() => select(group)}
            />
          ))
      }
    </List>
  ) : (
    <NoContent message="No groups yet..." />
  );
};

GroupList.propTypes = {
  selected: PropTypes.array,
  onChange: PropTypes.func,
};

GroupList.defaultProps = {
  selected: [],
  onChange: () => {},
};

export default GroupList;
