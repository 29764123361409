import React from 'react';
import ReactLoader from 'react-loader-spinner';

const Loader = props => {
  return <ReactLoader {...props} />;
};

Loader.defaultProps = {
  type: 'ThreeDots',
  color: '#3182CE',
  width: 40,
  height: 40
};

export default Loader;
