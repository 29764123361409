import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatchContext, useGroups } from 'providers/DispatchProvider';
import DepartmentList from 'components/DepartmentList';
import { Button, Card, Separator, NotFound } from 'components/ui';
import API from 'api';

const UpdateGroup = props => {
  const params = useParams();
  const history = useHistory();
  const dispatchContext = useDispatchContext();
  const groups = useGroups();

  const groupId = parseInt(params.groupId) || null;

  const [isProcessing, setIsProcessing] = useState(false);
  const [name, setName] = useState('');
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    const group = groups.find(item => item.id === groupId);

    if (group) {
      setName(group.name);
      setDepartments(group.departments);
    }
  }, [groupId, groups]);

  function update() {
    setIsProcessing(true);

    API.groups
      .update(groupId, {
        name,
        departments
      })
      .then(response => {
        const group = response.data.group;
        dispatchContext.dispatch({ type: 'UPDATE_GROUP', group });
        alert('Group has been updated');
      })
      .catch(error => alert(error))
      .finally(() => setIsProcessing(false));
  }

  function remove() {
    if (!window.confirm('Are you sure you want to delete this group?')) {
      return;
    }

    API.groups
      .remove(groupId)
      .then(response => {
        dispatchContext.dispatch({ type: 'REMOVE_GROUP', groupId });
        history.replace('/groups');
        alert('Group has been deleted');
      })
      .catch(error => alert(error));
  }

  if (!groupId) {
    return <NotFound />;
  }

  return (
    <div className="w-full">
      <Card title="Group Information">
        <div className="flex justify-between items-center w-full mb-2 py-2 px-4">
          <span />

          <div>
            <Button danger onClick={remove}>
              Remove Group
            </Button>
          </div>
        </div>

        <div className="mt-4">
          <label className="label">Name</label>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>

        <Separator and text="Select Departments to be in group" />

        <div className="mt-4">
          <label className="label">Departments</label>

          <DepartmentList
            selected={departments}
            onChange={selected => setDepartments(selected)}
          />
        </div>

        <div className="flex justify-center mt-6">
          <Button
            success
            disabled={
              isProcessing || name.length === 0 || departments.length === 0
            }
            loading={isProcessing}
            onClick={update}
          >
            {isProcessing ? 'Updating Group' : 'Update'}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default UpdateGroup;
