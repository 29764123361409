import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Marker } from 'google-maps-react';
import { useTeam } from 'providers/TeamProvider';
import { Button, GoogleMap } from 'components/ui';

Modal.setAppElement('#root');

const styles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const MapPicker = (props) => {
  const team = useTeam();

  const marker = useMemo(() => {
    if (!props.latitude || !props.longitude) {
      return null;
    }

    return (
      <Marker
        title="Incident"
        icon={require('assets/map-icons/incident.png')}
        position={{
          lat: props.latitude,
          lng: props.longitude,
        }}
      />
    );
  }, [props.latitude, props.longitude]);

  if (!props.visible) {
    return <div></div>;
  }

  function mapClick(_, __, event) {
    const latitude = event.latLng.lat();
    const longitude = event.latLng.lng();

    if (latitude && longitude) {
      props.onChooseCoordinates(`${latitude}`, `${longitude}`);
    }
  }

  return (
    <Modal
      isOpen={true}
      onRequestClose={props.onClose}
      contentLabel="Example Modal"
      style={styles}
    >
      <div style={{ width: '500px', height: '560px' }}>
        <div style={{ width: '500px', height: '500px' }}>
          <GoogleMap
            zoom={12}
            initialCenter={{
              lat: team.latitude,
              lng: team.longitude,
            }}
            onClick={mapClick}
          >
            {marker}
          </GoogleMap>
        </div>

        <div className="w-full" style={{ marginTop: '10px', height: '50px' }}>
          <Button block danger onClick={props.onClose}>
            Close Map Picker
          </Button>
        </div>
      </div>
    </Modal>
  );
};

MapPicker.propTypes = {
  visible: PropTypes.bool.isRequired,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  onChooseCoordinates: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

MapPicker.defaultProps = {
  latitude: null,
  longitude: null,
};

export default MapPicker;
