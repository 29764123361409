import axios from 'axios';
import { BASE_URL } from 'api/index';

export default {
  send(teamId, data) {
    return axios.post(`${BASE_URL}/dispatch/${teamId}/incidents`, data);
  },

  close(incidentId) {
    return axios.post(`${BASE_URL}/dispatch-incidents/${incidentId}/close`);
  },

  fetchIncidents(teamId) {
    return axios.get(`${BASE_URL}/dispatch/${teamId}/incidents`);
  },

  fetchResponders(teamId, departmentId) {
    return axios.get(
      `${BASE_URL}/dispatch/${teamId}/departments/${departmentId}/responders`
    );
  }
};
