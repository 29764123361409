import React from 'react';

const HelpText = props => (
  <div
    className={`max-w-full my-1 text-xs italic text-${
      props.danger ? 'red' : 'gray'
    }-600`}
  >
    {props.children}
  </div>
);

HelpText.defaultProps = {
  danger: false
};

export default HelpText;
