import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDepartments } from 'providers/DispatchProvider';
import DepartmentResponderStats from './DepartmentResponderStats';
import { Separator } from 'components/ui';

function ResponderStats(props) {
  const departments = useDepartments();

  const deployedDepartments = useMemo(
    () =>
      !props.incident
        ? []
        : props.incident.departments
            .filter(i => !props.incident.closed_departments.includes(i))
            .map(i => departments.find(j => j.id === i)),
    [departments, props.incident]
  );

  const closedDepartments = useMemo(
    () =>
      !props.incident
        ? []
        : props.incident.closed_departments.map(i =>
            departments.find(j => j.id === i)
          ),
    [departments, props.incident]
  );

  return (
    <div
      className="h-full mr-2 p-4 bg-gray-100 shadow-inner overflow-y-auto"
      style={{ width: 340 }}
    >
      <h1 className="text-gray-700 font-bold text-xl">Responder Stats</h1>

      <p className="mt-1 px-1 text-xs text-gray-600 italic">
        * Responder counts will update automatically every 60 seconds.
      </p>

      <Separator />

      {deployedDepartments.map(item => (
        <DepartmentResponderStats
          key={item.id}
          title={item.name}
          departmentId={item.id}
          loading={true}
        />
      ))}

      {closedDepartments.map(item => (
        <div key={item.id}>
          <div>
            <span className="line-through text-gray-600 font-semibold">
              {item.name}
            </span>
            <span className="text-red-600 font-bold ml-2">(Closed)</span>

            <Separator />
          </div>
        </div>
      ))}
    </div>
  );
}

ResponderStats.propTypes = {
  incident: PropTypes.object.isRequired
};

export default ResponderStats;
