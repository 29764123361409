const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return { ...state, error: action.error };
    case 'SET_DEPARTMENTS':
      return { ...state, departments: action.departments };
    case 'SET_GROUPS':
      return { ...state, groups: action.groups };
    case 'ADD_GROUP':
      return { ...state, groups: [action.group, ...state.groups] };
    case 'UPDATE_GROUP':
      return {
        ...state,
        groups: [...state.groups].map(item =>
          item.id === action.group.id ? action.group : item
        )
      };
    case 'REMOVE_GROUP':
      return {
        ...state,
        groups: [...state.groups].filter(item => item.id !== action.groupId)
      };
    case 'SET_INCIDENTS':
      return { ...state, incidents: action.incidents };
    case 'ADD_INCIDENT':
      return { ...state, incidents: [action.incident, ...state.incidents] };
    case 'UPDATE_INCIDENT':
      return {
        ...state,
        incidents: [...state.incidents].map(item =>
          item.id === action.incident.id ? action.incident : item
        )
      };
    case 'REMOVE_INCIDENT':
      return {
        ...state,
        incidents: [...state.incidents].filter(
          item => item.id !== action.incident.id
        )
      };
    default:
      throw new Error('Invalid dispatch reducer action');
  }
};

export default reducer;
