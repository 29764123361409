import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDialogState, Dialog, DialogDisclosure } from 'reakit/Dialog';
import { useDispatchContext } from 'providers/DispatchProvider';
import IncidentUpdate from './IncidentUpdate';
import { Button } from 'components/ui';
import API from 'api';

function IncidentInformation(props) {
  const dispatchContext = useDispatchContext();

  const history = useHistory();
  const dialog = useDialogState();

  const [isUpdating, setIsUpdating] = useState(false);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    API.incidents
      .fetchNotes(props.incident.id)
      .then(response => setNotes(response.data.notes));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.incident]);

  function close() {
    if (!window.confirm('Are you sure you want to close this incident?')) {
      return;
    }

    API.dispatch
      .close(props.incident.id)
      .then(() => {
        dispatchContext.dispatch({
          type: 'REMOVE_INCIDENT',
          incident: props.incident
        });
        history.replace('/');
      })
      .catch(() => alert('Failed to close incident'));
  }

  return isUpdating ? (
    <IncidentUpdate
      incident={props.incident}
      onFinishedEditing={() => setIsUpdating(false)}
    />
  ) : (
    <>
      <div className="w-full mb-2 py-2 px-4 bg-gray-100 shadow-inner">
        <div className="flex justify-between w-full">
          <span className="flex-1 px-1 underline text-lg text-gray-700">
            <strong>Incident Number: </strong>
            {props.incident.incident_name}
          </span>

          <span className="flex">
            {!!props.incident && (
              <div>
                <Button primary onClick={() => setIsUpdating(true)}>
                  Update Incident
                </Button>
              </div>
            )}

            {!!props.incident && (
              <div>
                <Button danger className="ml-2" onClick={close}>
                  Close Incident
                </Button>
              </div>
            )}
          </span>
        </div>

        <div className="flex-1 mr-4">
          <table className="text-sm text-gray-700">
            <tbody>
              <tr>
                <td className="py-1 px-2">
                  <strong>Dispatched At: </strong>
                </td>
                <td className="py-1 px-2">
                  {props.incident.local_created_at.datetime}
                </td>
              </tr>

              <tr>
                <td className="py-1 px-2">
                  <strong>Incident Type: </strong>
                </td>
                <td className="py-1 px-2">
                  {props.incident.incident_type_name}
                </td>
              </tr>

              <tr>
                <td className="py-1 px-2">
                  <strong>Address: </strong>
                </td>
                <td className="py-1 px-2">{props.incident.address}</td>
              </tr>

              <tr>
                <td className="py-1 px-2">
                  <strong>Coordinates: </strong>
                </td>
                <td className="py-1 px-2">
                  {props.incident.latitude && props.incident.longitude
                    ? `${props.incident.latitude}, ${props.incident.longitude}`
                    : 'N/A'}
                </td>
              </tr>

              <tr>
                <td className="py-1 px-2">
                  <strong>Current Description: </strong>
                </td>
                <td className="py-1 px-2">{props.incident.description}</td>
              </tr>

              {notes.length > 1 && (
                <tr>
                  <td className="py-1 px-2">
                    <strong>Previous Descriptions: </strong>
                  </td>
                  <td className="py-1 px-2">
                    <>
                      <DialogDisclosure {...dialog}>View</DialogDisclosure>

                      <Dialog
                        {...dialog}
                        aria-label="Descriptions"
                        modal={false}
                        style={{
                          position: 'static',
                          transform: 'none',
                          marginTop: 8
                        }}
                      >
                        <ul className="ml-3 list-disc">
                          {notes.map(note => (
                            <li key={note.id}>{note.message}</li>
                          ))}
                        </ul>
                      </Dialog>
                    </>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

IncidentInformation.propTypes = {
  incident: PropTypes.object.isRequired
};

export default IncidentInformation;
