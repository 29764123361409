import React from 'react';
import DispatchStats from 'components/dispatch/DispatchStats';
import DispatchForm from 'components/dispatch/DispatchForm';

const Dashboard = () => {
  return (
    <div className="flex justify-center w-full">
      <div className="w-full 3xl:w-2/3">
        <DispatchStats />
        <DispatchForm />
      </div>
    </div>
  );
};

export default Dashboard;
