import React from 'react';
import { Button as ReakitButton } from 'reakit/Button';
import PropTypes from 'prop-types';
import Loader from 'components/ui/Loader';

const Button = props => {
  let color = buttonColor(props);
  let classes = props.link
    ? `button-link`
    : `button bg-${color}-600 hover:bg-${color}-700`;
  classes = props.block ? `${classes} w-full` : classes;
  classes = props.disabled ? `${classes} disabled` : classes;
  classes = `${classes} ${props.className}`;

  const icon = props.icon ? (
    <img src={require(`assets/${props.icon}`)} alt="icon" />
  ) : (
    false
  );

  return (
    <ReakitButton className={classes} onClick={props.onClick}>
      {props.loading ? (
        <span className="mr-2">
          <Loader color="#fff" width={25} height={25} />
        </span>
      ) : (
        <span>{icon}</span>
      )}

      <span>{props.children}</span>
    </ReakitButton>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  disabled: PropTypes.bool,
  danger: PropTypes.bool,
  warning: PropTypes.bool,
  success: PropTypes.bool,
  secondary: PropTypes.bool,
  onClick: PropTypes.func
};

Button.defaultProps = {
  className: '',
  loading: false,
  block: false,
  link: false,
  disabled: false,
  danger: false,
  warning: false,
  success: false,
  secondary: false,
  onClick: () => {}
};

function buttonColor(props) {
  if (props.danger) {
    return 'red';
  } else if (props.warning) {
    return 'orange';
  } else if (props.success) {
    return 'green';
  } else if (props.secondary) {
    return 'teal';
  } else {
    return 'blue';
  }
}

export default Button;
