import React from 'react';
import PropTypes from 'prop-types';
import ReactSwitch from 'react-switch';

const Switch = props => (
  <label className="flex items-center">
    <ReactSwitch {...props} />
    <span className="ml-2 text-gray-700 text-sm">{props.text}</span>
  </label>
);

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  text: PropTypes.string,
  uncheckedIcon: PropTypes.bool
};

Switch.defaultProps = {
  text: '',
  uncheckedIcon: false
};

export default Switch;
