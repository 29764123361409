import React, { useContext, useEffect, useReducer } from 'react';
import authReducer from 'reducers/auth';
import API from 'api';
import SplashPage from 'pages/Splash';
import LoginPage from 'pages/Login';

const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';

const initialState = {
  error: null,
  loading: true,
  user: null,
};

const AuthContext = React.createContext(initialState);

const AuthProvider = (props) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const content = state.loading ? (
    <SplashPage />
  ) : state.user ? (
    props.children
  ) : (
    <LoginPage />
  );

  useEffect(() => {
    const token = window.localStorage.getItem(ACCESS_TOKEN_KEY);
    if (!token) {
      dispatch({ type: 'SET_IS_LOADING', isLoading: false });
      dispatch({ type: 'SET_USER', user: null });
      return;
    }

    API.setToken(token);
    fetchProfile();
  }, []);

  function fetchProfile() {
    API.auth
      .fetchProfile()
      .then((response) => {
        dispatch({ type: 'SET_USER', user: response.data.profile });

        // update users timezone
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        API.auth.updateProfile({ timezone });
      })
      .catch(() => {
        dispatch({ type: 'SET_USER', user: null });
      })
      .finally(() => {
        dispatch({ type: 'SET_IS_LOADING', isLoading: false });
      });
  }

  function login(email, password) {
    return API.auth.login(email, password).then((response) => {
      const token = response.data.access_token;
      window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
      API.setToken(token);

      fetchProfile();
    });
  }

  function logout() {
    dispatch({ type: 'SET_USER', user: null });
    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
        login,
        logout,
      }}
      {...props}
    >
      {content}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

function useUser() {
  const { state } = useAuth();
  return state.user;
}

export {
  AuthProvider as default,
  AuthContext,
  useAuth,
  useUser,
  ACCESS_TOKEN_KEY,
};
