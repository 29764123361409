import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useIncident } from 'providers/DispatchProvider';
import IncidentInformation from 'components/incident/IncidentInformation';
import ResponderStats from 'components/incident/ResponderStats';
import IncidentMap from 'components/incident/IncidentMap';

const Incident = props => {
  const params = useParams();
  const incidentId = params.id ? parseInt(params.id) : null;

  const incident = useIncident(incidentId);

  if (!incident) {
    return <Redirect to="/" />;
  }

  return (
    <div className="flex flex-col w-full">
      <IncidentInformation incident={incident} />

      <div className="flex flex-1 w-full">
        <ResponderStats incident={incident} />

        <div className="flex-grow h-full">
          <IncidentMap incident={incident} />
        </div>
      </div>
    </div>
  );
};

export default Incident;
