import React, { useContext, useEffect, useReducer } from 'react';
import teamReducer from 'reducers/team';
import API from 'api';
import SplashPage from 'pages/Splash';
import TeamSelectPage from 'pages/TeamSelect';

const SELECTED_TEAM_KEY = 'SELECTED_TEAM';

const initialState = {
  error: null,
  loading: true,
  team: null,
  teams: []
};

const TeamContext = React.createContext(initialState);

const TeamProvider = props => {
  const [state, dispatch] = useReducer(teamReducer, initialState);

  const content = state.loading ? (
    <SplashPage />
  ) : state.team ? (
    props.children
  ) : (
    <TeamSelectPage />
  );

  useEffect(() => {
    const selectedTeamId = window.localStorage.getItem(SELECTED_TEAM_KEY);
    fetchTeams(selectedTeamId);
  }, []);

  async function fetchTeams(selectedTeamId = null) {
    try {
      const response = await API.teams.fetchMyTeams();
      const teams = response.data.teams;

      dispatch({ type: 'SET_TEAMS', teams });

      if (teams.length === 0) {
        throw new Error('No teams found...');
      }

      if (selectedTeamId) {
        const team = teams.find(item => item.id === parseInt(selectedTeamId));
        dispatch({ type: 'SET_TEAM', team });
      } else if (teams.length === 1) {
        const team = teams[0];
        window.localStorage.setItem(SELECTED_TEAM_KEY, team.id);
        dispatch({ type: 'SET_TEAM', team });
      }
    } catch (error) {
      dispatch({ type: 'SET_ERROR', error });
    } finally {
      dispatch({ type: 'SET_IS_LOADING', isLoading: false });
    }
  }

  function clear() {
    dispatch({ type: 'SET_TEAM', team: null });
  }

  return (
    <TeamContext.Provider
      value={{
        state,
        dispatch,
        clear
      }}
      {...props}
    >
      {content}
    </TeamContext.Provider>
  );
};

function useTeamContext() {
  const context = useContext(TeamContext);

  if (!context) {
    throw new Error('useTeam must be used within an TeamProvider');
  }

  return context;
}

function useTeam() {
  const context = useTeamContext();
  return context.state.team;
}

export {
  TeamProvider as default,
  TeamContext,
  useTeamContext,
  useTeam,
  SELECTED_TEAM_KEY
};
