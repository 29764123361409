import React, { useState } from 'react';
import { useAuth } from 'providers/AuthProvider';
import { Button, Card, HelpText } from 'components/ui';

const Login = () => {
  const authContext = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function login() {
    setIsLoading(true);

    authContext
      .login(email, password)
      .catch((error) => {
        if (error.response.status === 401) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage('Uh Oh! Something went wrong!');
        }
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <div className="h-screen flex justify-center items-center bg-gray-900">
      <Card>
        <div style={{ width: 350 }}>
          <div className="flex justify-center">
            <img
              src={require('assets/img/fireq-bst-logo.svg')}
              alt="FireQ Dispatch"
              className="w-3/4 h-32"
            />
          </div>

          {errorMessage && <HelpText danger>{errorMessage}</HelpText>}

          <div className="mt-2 mb-4">
            <label className="label">Email</label>

            <input
              type="text"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label className="label">Password</label>

            <input
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <Button
              primary
              block
              loading={isLoading}
              onClick={login}
              className="mb-1"
            >
              Login
            </Button>

            {/* <Button danger block onClick={() => console.log('Forgot Password')}>
              Logout
            </Button> */}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Login;
