import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import {
  useDepartments,
  useIncidents,
  useDeployedDepartments
} from 'providers/DispatchProvider';
import { Card } from 'components/ui';

const DispatchStats = props => {
  const departments = useDepartments();
  const deployedDepartments = useDeployedDepartments();
  const incidents = useIncidents();

  const countAvailableDepartments = Math.max(
    0,
    departments.length - deployedDepartments.length
  );

  return (
    <div className="flex justify-around mb-8">
      <div className="w-1/3 px-1">
        <Card title="Available Departments" titleCentered>
          <div className="mt-2 w-full h-24 text-center">
            <CircularProgressbar
              text={`${countAvailableDepartments}`}
              value={countAvailableDepartments}
              maxValue={departments.length}
              className="h-full"
            />
          </div>
        </Card>
      </div>

      <div className="w-1/3 px-1">
        <Card title="Deployed Departments" titleCentered>
          <div className="w-full h-24 text-center">
            <CircularProgressbar
              text={`${deployedDepartments.length}`}
              value={deployedDepartments.length}
              maxValue={departments.length}
              className="h-full"
            />
          </div>
        </Card>
      </div>

      <div className="w-1/3 px-1">
        <Card title="Active Incidents" titleCentered>
          <div className="w-full h-24 text-center">
            <CircularProgressbar
              text={`${incidents.length}`}
              value={incidents.length}
              maxValue={10}
              className="h-full"
              styles={buildStyles({
                pathColor: `red`,
                textColor: 'red'
              })}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default DispatchStats;
