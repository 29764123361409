import React from 'react';
import PropTypes from 'prop-types';
import { useDepartments } from 'providers/DispatchProvider';
import { List, ListItem } from 'components/ui';

const DepartmentList = (props) => {
  const departments = useDepartments();

  function select(department) {
    const index = props.selected.findIndex((item) => item === department.id);

    let value = [...props.selected];
    if (index > -1) {
      value.splice(index, 1);
    } else {
      value.push(department.id);
    }

    props.onChange(value);
  }

  return (
    <List heading="Departments" searchable height={417}>
      {(query) =>
        departments
          .filter(
            (department) =>
              query.length === 0 ||
              department.name.toLowerCase().includes(query.toLowerCase())
          )
          .map((department) => (
            <ListItem
              key={department.id}
              title={department.name}
              selectable
              selected={props.selected.includes(department.id)}
              onClick={() => select(department)}
            />
          ))
      }
    </List>
  );
};

DepartmentList.propTypes = {
  selected: PropTypes.array,
  onChange: PropTypes.func,
};

DepartmentList.defaultProps = {
  selected: [],
  onChange: () => {},
};

export default DepartmentList;
