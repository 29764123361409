/*global google*/
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactPlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { useTeam } from 'providers/TeamProvider';

const PlacesAutocomplete = (props) => {
  const team = useTeam();

  const searchOptions = useMemo(
    () => ({
      location: new google.maps.LatLng(team.latitude, team.longitude),
      radius: team.options.operating_radius || 50 * 1000,
    }),
    [team]
  );

  async function selectPlace(address) {
    try {
      const results = await geocodeByAddress(address);
      const coordinates = await getLatLng(results[0]);

      props.onSelect({
        address,
        ...coordinates,
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <ReactPlacesAutocomplete
      value={props.value}
      highlightFirstSuggestions
      searchOptions={searchOptions}
      onChange={(val) => props.onChange(val)}
      onSelect={(val) => selectPlace(val)}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="relative w-full">
          <input
            {...getInputProps({
              placeholder: 'Search address and autofill...',
              className: 'location-search-input',
            })}
          />

          {(loading || suggestions.length > 0) && (
            <div className="absolute w-full mt-1 border z-10">
              {loading && (
                <div className="py-2 px-4 text-sm bg-gray-200">Loading...</div>
              )}

              {suggestions.map((suggestion) => {
                const className = `py-2 px-4 text-gray-800 text-sm font-medium border-b border-gray-400 cursor-pointer ${
                  suggestion.active ? 'bg-blue-300' : 'bg-gray-200'
                } hover:bg-blue-300`;

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </ReactPlacesAutocomplete>
  );
};

PlacesAutocomplete.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
};

PlacesAutocomplete.defaultProps = {
  value: '',
  onChange: () => {},
};

export default PlacesAutocomplete;
