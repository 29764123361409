import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useTeam } from 'providers/TeamProvider';
import { useDispatchContext } from 'providers/DispatchProvider';
import API from 'api';
import MapPicker from 'components/dispatch/MapPicker';
import DepartmentList from 'components/DepartmentList';
import DepartmentGroupList from 'components/DepartmentGroupList';
import GroupList from 'components/GroupList';
import {
  Button,
  Card,
  PlacesAutocomplete,
  Separator,
  Switch,
} from 'components/ui';

const DispatchForm = (props) => {
  const team = useTeam();
  const dispatchContext = useDispatchContext();

  const [incidentTypes, setIncidentTypes] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [dispatchByGroup, setDispatchByGroup] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [departmentGroups, setDepartmentGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [incidentType, setIncidentType] = useState(-1);
  const [otherIncidentType, setOtherIncidentType] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [isMapPickerVisible, setIsMapPickerVisible] = useState(false);

  useEffect(() => {
    fetchIncidentTypes();
  }, []);

  useEffect(() => {
    setDefaultOptions(team);
  }, [team]);

  function fetchIncidentTypes() {
    API.data
      .fetchIncidentTypes()
      .then((response) => setIncidentTypes(response.data.incident_types))
      .catch((error) => console.error(error));
  }

  function setDefaultOptions(team) {
    setDepartments(team.options.default_departments || []);
    setAddress(
      team.options.default_location ? team.options.default_location.address : ''
    );
    setLatitude(
      team.options.default_location
        ? team.options.default_location.latitude
        : ''
    );
    setLongitude(
      team.options.default_location
        ? team.options.default_location.longitude
        : ''
    );
  }

  function selectPlace(place) {
    setAddress(place.address);
    setLatitude(place.lat);
    setLongitude(place.lng);
  }

  function toggleDispatchGroup(enabled) {
    setDepartments([]);
    setGroups([]);
    setDispatchByGroup(enabled);
  }

  function updateDepartmentGroups(val) {
    setDepartmentGroups(val);
    setDepartments(Object.keys(val).filter((key) => val[key].length > 0));
  }

  async function create() {
    setIsProcessing(true);

    try {
      const response = await API.dispatch.send(team.id, {
        dispatchByGroup,
        departments,
        departmentGroups: departmentGroups,
        groups,
        incidentType: incidentType > 0 ? incidentType : null,
        otherIncidentType,
        description,
        address,
        latitude,
        longitude,
      });

      const incident = response.data.incident;
      dispatchContext.dispatch({ type: 'ADD_INCIDENT', incident });

      clear();
      setIsProcessing(false);

      if (
        window.confirm(
          'Incident has successfully been dispatched. Do you want to go to the incident page?'
        )
      ) {
        props.history.push(`/incidents/${incident.id}`);
      }
    } catch (error) {
      setIsProcessing(false);
      console.error(error);
    }
  }

  function clear() {
    setDefaultOptions(team);
    setIncidentType(-1);
    setOtherIncidentType('');
    setDescription('');
  }

  const readyToSubmit = useMemo(
    () =>
      (groups.length > 0 || departments.length > 0) &&
      (incidentType > 0 ||
        (incidentType === 0 && otherIncidentType.length > 0)) &&
      (address.length > 0 || (latitude.length > 0 && longitude.length > 0)),
    [
      groups,
      departments,
      incidentType,
      otherIncidentType,
      address,
      latitude,
      longitude,
    ]
  );

  return (
    <div className="flex flex-wrap">
      <div className="w-full xl:w-96 mb-8 xl:mb-0 px-1">
        {team.can_dispatch_by_group ? (
          <Card title="Departments and Groups">
            <DepartmentGroupList onChange={updateDepartmentGroups} />
          </Card>
        ) : (
          <Card title={dispatchByGroup ? 'Groups' : 'Departments'}>
            {dispatchContext.state.groups.length > 0 && (
              <div className="py-1 pb-3">
                <Switch
                  text="Dispatch by Group"
                  checked={dispatchByGroup}
                  onChange={toggleDispatchGroup}
                />
              </div>
            )}

            {dispatchByGroup ? (
              <GroupList
                selected={groups}
                onChange={(selected) => setGroups(selected)}
              />
            ) : (
              <DepartmentList
                selected={departments}
                onChange={(selected) => setDepartments(selected)}
              />
            )}
          </Card>
        )}
      </div>

      <div className="flex-1 px-1">
        <Card title="Incident Information">
          <div className="mt-4">
            <label className="label">Incident Type</label>

            <select
              value={incidentType}
              onChange={(e) =>
                setIncidentType(
                  e.target.value >= 0 ? parseInt(e.target.value) : undefined
                )
              }
            >
              <option value={-1}>---</option>
              <option value={0}>--- Other ---</option>

              {incidentTypes.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          {incidentType === 0 && (
            <div className="mt-4">
              <label className="label">Other Incident Type</label>
              <input
                type="text"
                placeholder="Other Incident Type"
                value={otherIncidentType}
                onChange={(e) => setOtherIncidentType(e.target.value)}
              />
            </div>
          )}

          <div className="mt-4">
            <label className="label">Description</label>
            <input
              type="text"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <Separator text="Search for address" />

          <div className="z-10">
            <label className="label">Address</label>
            <PlacesAutocomplete
              value={address}
              onChange={(val) => setAddress(val)}
              onSelect={selectPlace}
            />
          </div>

          <Separator or text="Enter coordinates" />

          <button
            type="button"
            className="mb-4 text-blue-600 underline hover:text-blue-700"
            onClick={() => setIsMapPickerVisible(true)}
          >
            Choose Coordinates from Map
          </button>

          <MapPicker
            visible={isMapPickerVisible}
            latitude={latitude}
            longitude={longitude}
            onChooseCoordinates={(latitude, longitude) => {
              setLatitude(latitude);
              setLongitude(longitude);
            }}
            onClose={() => setIsMapPickerVisible(false)}
          />

          <div className="flex">
            <div className="w-1/2 mr-1">
              <label className="label">Latitude</label>
              <input
                type="text"
                placeholder="Latitude"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
              />
            </div>

            <div className="w-1/2 mr-1">
              <label className="label">Longitude</label>
              <input
                type="text"
                placeholder="Longitude"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
              />
            </div>
          </div>

          <div className="flex justify-center mt-6">
            <Button
              danger
              disabled={isProcessing || !readyToSubmit}
              loading={isProcessing}
              onClick={create}
            >
              {isProcessing ? 'Dispatching' : 'Dispatch Incident'}
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default withRouter(DispatchForm);
