import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import dispatchReducer from 'reducers/dispatch';
import { useTeam } from 'providers/TeamProvider';
import API from 'api';

const initialState = {
  error: null,
  departments: [],
  groups: [],
  incidents: []
};

const DispatchContext = React.createContext(initialState);

const DispatchProvider = props => {
  const team = useTeam();
  const [state, dispatch] = useReducer(dispatchReducer, initialState);

  useEffect(() => {
    fetchDepartments(team.id);
    fetchGroups(team.id);
    fetchIncidents(team.id);
  }, [team.id]);

  useEffect(() => {
    if (state.incidents.length === 0) {
      return;
    }

    const interval = setInterval(() => {
      fetchIncidents(team.id);
    }, 1 * 60 * 1000);

    return () => clearInterval(interval);
  }, [team.id, state.incidents]);

  function fetchDepartments(teamId) {
    API.departments
      .fetch(teamId)
      .then(response =>
        dispatch({
          type: 'SET_DEPARTMENTS',
          departments: response.data.departments
        })
      )
      .catch(error => dispatch({ type: 'SET_ERROR', error }));
  }

  function fetchGroups(teamId) {
    API.groups
      .fetch(teamId)
      .then(response =>
        dispatch({
          type: 'SET_GROUPS',
          groups: response.data.groups
        })
      )
      .catch(error => console.error(error));
  }

  function fetchIncidents(teamId) {
    API.dispatch
      .fetchIncidents(teamId)
      .then(response =>
        dispatch({
          type: 'SET_INCIDENTS',
          incidents: response.data.incidents
        })
      )
      .catch(error => console.error(error));
  }

  return (
    <DispatchContext.Provider
      value={{
        state,
        dispatch
      }}
      {...props}
    >
      {props.children}
    </DispatchContext.Provider>
  );
};

function useDispatchContext() {
  const context = useContext(DispatchContext);

  if (!context) {
    throw new Error(
      'useDispatchContext must be used within an DispatchContext'
    );
  }

  return context;
}

function useDepartments() {
  const context = useDispatchContext();
  return context.state.departments;
}

function useGroups() {
  const context = useDispatchContext();
  return context.state.groups;
}

function useIncidents() {
  const context = useDispatchContext();
  return context.state.incidents;
}

function useIncident(incidentId) {
  const incidents = useIncidents();
  return incidents.find(item => item.id === incidentId);
}

function useDeployedDepartments(onlyIds = true) {
  const departments = useDepartments();
  const incidents = useIncidents();

  const deployedDepartments = useMemo(() => {
    let deployed = [];

    incidents.forEach(item => {
      deployed = deployed
        .concat(item.departments)
        .filter(
          i => !item.closed_departments || !item.closed_departments.includes(i)
        );
    });

    deployed = [...new Set(deployed)];

    if (!onlyIds) {
      deployed = deployed.map(i => departments.find(d => d.id === i));
    }

    return deployed.filter(item => item);
  }, [departments, incidents, onlyIds]);

  return deployedDepartments;
}

export {
  DispatchProvider as default,
  DispatchContext,
  useDispatchContext,
  useDepartments,
  useGroups,
  useIncidents,
  useIncident,
  useDeployedDepartments
};
