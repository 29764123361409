import React, { useState } from 'react';
import { useTeam } from 'providers/TeamProvider';
import { useDispatchContext } from 'providers/DispatchProvider';
import API from 'api';
import DepartmentList from 'components/DepartmentList';
import { Button, Card, Separator } from 'components/ui';

const CreateGroup = props => {
  const team = useTeam();
  const dispatchContext = useDispatchContext();

  const [isProcessing, setIsProcessing] = useState(false);
  const [name, setName] = useState('');
  const [departments, setDepartments] = useState([]);

  function create() {
    setIsProcessing(true);

    API.groups
      .create(team.id, {
        name,
        departments
      })
      .then(response => {
        const group = response.data.group;
        dispatchContext.dispatch({ type: 'ADD_GROUP', group });
        clear();
      })
      .catch(error => alert(error))
      .finally(() => setIsProcessing(false));
  }

  function clear() {
    setIsProcessing(false);
    setName('');
    setDepartments([]);
  }

  return (
    <div className="w-full">
      <Card title="Group Information">
        <div className="mt-4">
          <label className="label">Name</label>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>

        <Separator and text="Select Departments to be in group" />

        <div className="mt-4">
          <label className="label">Departments</label>

          <DepartmentList
            selected={departments}
            onChange={selected => setDepartments(selected)}
          />
        </div>

        <div className="flex justify-center mt-6">
          <Button
            success
            disabled={
              isProcessing || name.length === 0 || departments.length === 0
            }
            loading={isProcessing}
            onClick={create}
          >
            {isProcessing ? 'Creating Group' : 'Create'}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default CreateGroup;
